
const AuthPage = () => import('@/pages/auth/AuthPage.vue')
const ReferralPage = () => import('@/pages/auth/ReferralPage.vue')

export default [
  {
    path: 'auth',
    name: 'auth',
    component: AuthPage,
    meta: {
      noBackButton: true,
      noAuthRequired: true,
    },
  },
  {
    path: 'invite',
    name: 'referralEntry',
    component: ReferralPage,
    meta: {
      noAuthRequired: true,
      partialAuthPermitted: true,
    },
    children: [
      {
        path: ':referralCode',
        name: 'referral',
      },
    ],
  },
  {
    path: 'referral',
    name: 'legacyReferralEntry',
    component: ReferralPage,
    redirect: { name: 'referralEntry' },
    meta: {
      noAuthRequired: true,
      partialAuthPermitted: true,
    },
    children: [
      {
        path: ':referralCode',
        name: 'legacyReferral',
        redirect: ({ params }) => ({ name: 'referral', params }),
      },
    ],
  },
]
