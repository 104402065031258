
import ParentPage from '@/pages/ParentPage.vue'

const ConsoleHomePage = () => import('@/pages/console/ConsoleHomePage.vue')

const ConsoleUsersPage = () => import('@/pages/console/ConsoleUsersPage.vue')

const ConsoleBrandsPage = () => import('@/pages/console/ConsoleBrandsPage.vue')
const ConsoleBrandCampaignCohortsPage = () => import('@/pages/console/ConsoleBrandCampaignCohortsPage.vue')
const ConsoleBrandCampaignOpsTab = () => import('@/pages/console/brandCampaign/ConsoleBrandCampaignOpsTab.vue')
const ConsoleBrandCampaignMetricsTab = () => import('@/pages/console/brandCampaign/ConsoleBrandCampaignMetricsTab.vue')
const ConsoleBrandCampaignConstraintsTab = () => import('@/pages/console/brandCampaign/ConsoleBrandCampaignConstraintsTab.vue')
const ConsoleBrandCampaignPublicTab = () => import('@/pages/console/brandCampaign/ConsoleBrandCampaignPublicTab.vue')
const ConsoleBrandCampaignMessagingTab = () => import('@/pages/console/brandCampaign/ConsoleBrandCampaignMessagingTab.vue')
const ConsoleBrandCampaignBrandFacingTab = () => import('@/pages/console/brandCampaign/ConsoleBrandCampaignBrandFacingTab.vue')
const ConsoleBrandCampaignRecruitingTab = () => import('@/pages/console/brandCampaign/ConsoleBrandCampaignRecruitingTab.vue')

const ConsoleDmsPage = () => import('@/pages/console/ConsoleDmsPage.vue')

const ConsoleBankPage = () => import('@/pages/console/bank/ConsoleBankPage.vue')
const ConsoleBankTotalsPage = () => import('@/pages/console/bank/ConsoleBankTotalsPage.vue')
const ConsoleBankLedgersPage = () => import('@/pages/console/bank/ConsoleBankLedgersPage.vue')
const ConsoleBankAccountsPage = () => import('@/pages/console/bank/ConsoleBankAccountsPage.vue')
const ConsoleBankInvoicesPage = () => import('@/pages/console/bank/ConsoleBankInvoicesPage.vue')
const ConsoleBankExpensesPage = () => import('@/pages/console/bank/ConsoleBankExpensesPage.vue')
const ConsoleBankFinanceCasesPage = () => import('@/pages/console/bank/ConsoleBankFinanceCasesPage.vue')

const ConsoleAgenciesPage = () => import('@/pages/console/agencies/ConsoleAgenciesPage.vue')

const ConsoleCsvPage = () => import('@/pages/console/ConsoleCsvPage.vue')

const ConsoleTrackedSoundPage = () => import('@/pages/console/ConsoleTrackedSoundPage.vue')

const ConsoleSalesPage = () => import('@/pages/console/ConsoleSalesPage.vue')

export default [
  {
    path: '',
    name: 'console',
    component: ParentPage,
    redirect: { name: 'consoleHome' },
    meta: {
      authRequired: true,
      role: 'admin',
      insufficientAuthRedirect: { name: '404' },
      useHeirarchyForGoBack: true,
    },
    children: [
      {
        path: '',
        name: 'consoleHome',
        component: ConsoleHomePage,
        meta: {
          noBackButton: true,
          insufficientAuthRedirect: { name: '404' },
        },
      },
      {
        path: 'users',
        name: 'consoleUsers',
        component: ConsoleUsersPage,
        children: [
          {
            path: 'discovery',
            name: 'consoleUsersDiscovery',
            children: [
              {
                path: 'earn',
                name: 'consoleUsersDiscoveryEarn',
              },
              {
                path: 'grow',
                name: 'consoleUsersDiscoveryGrow',
              },
            ],
          },
        ],
      },
      {
        path: 'brands',
        name: 'consoleBrands',
        component: ConsoleBrandsPage,
        children: [
          {
            path: ':brandId?/campaigns',
            name: 'consoleBrand',
            component: ParentPage,
            children: [
              {
                path: ':brandCampaignId',
                name: 'consoleBrandCampaign',
                component: ParentPage,
                redirect: { name: 'consoleBrandCampaignOps' },
                children: [
                  {
                    path: 'ops',
                    name: 'consoleBrandCampaignOps',
                    meta: { brandCampaignTab: ConsoleBrandCampaignOpsTab },
                    children: [
                      {
                        path: 'off-platform',
                        name: 'consoleBrandCampaignOffPlatform',
                      },
                    ],
                  },
                  {
                    path: 'metrics',
                    name: 'consoleBrandCampaignMetrics',
                    meta: { brandCampaignTab: ConsoleBrandCampaignMetricsTab },
                  },
                  {
                    path: 'constraints',
                    name: 'consoleBrandCampaignConstraints',
                    meta: { brandCampaignTab: ConsoleBrandCampaignConstraintsTab },
                  },
                  {
                    path: 'public',
                    name: 'consoleBrandCampaignPublic',
                    meta: { brandCampaignTab: ConsoleBrandCampaignPublicTab },
                  },
                  {
                    path: 'messaging',
                    name: 'consoleBrandCampaignMessaging',
                    meta: { brandCampaignTab: ConsoleBrandCampaignMessagingTab },
                    children: [
                      {
                        path: 'single-send',
                        name: 'consoleBrandCampaignServerActions',
                      },
                    ],
                  },
                  {
                    path: 'brand-facing',
                    name: 'consoleBrandCampaignBrandFacing',
                    meta: { brandCampaignTab: ConsoleBrandCampaignBrandFacingTab },
                    children: [
                      {
                        path: 'brand-communications',
                        name: 'consoleBrandCampaignCommunications',
                      },
                    ],
                  },
                  {
                    path: 'recruiting',
                    name: 'consoleBrandCampaignRecruiting',
                    meta: { brandCampaignTab: ConsoleBrandCampaignRecruitingTab },
                    children: [
                      {
                        path: 'creator-recruitment',
                        name: 'consoleBrandCampaignCreatorRecruitment',
                        children: [
                          {
                            path: 'campaign/:emailCampaignId',
                            name: 'consoleBrandCampaignEmailActionGroups',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'ops/cohort/:cohort',
                    name: 'consoleBrandCampaignCohort',
                    meta: {
                      isConsoleBrandsChildPage: true,
                      noTopNav: true,
                    },
                    component: ConsoleBrandCampaignCohortsPage,
                    children: [
                      {
                        path: 'user/:userId',
                        name: 'consoleBrandCampaignUser',
                        redirect: { name: 'consoleBrandCampaignUserAccounts' },
                        children: [
                          {
                            path: 'accounts',
                            name: 'consoleBrandCampaignUserAccounts',
                          },
                          {
                            path: 'submission',
                            name: 'consoleBrandCampaignUserSubmission',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'campaign-groups',
            name: 'consoleBrandCampaignGroups',
          },
          {
            path: 'trends',
            name: 'consoleTrends',
            children: [
              {
                path: ':trendId',
                name: 'consoleTrend',
              },
            ],
          },
        ],
      },
      {
        path: 'dms',
        name: 'consoleDms',
        component: ConsoleDmsPage,
      },
      {
        path: 'bank',
        name: 'consoleBank',
        component: ConsoleBankPage,
        redirect: { name: 'consoleBankTotals' },
        children: [
          {
            path: 'totals',
            name: 'consoleBankTotals',
            component: ConsoleBankTotalsPage,
            redirect: { name: 'consoleBankIncomeTotals' },
            children: [
              {
                path: 'income-statement',
                name: 'consoleBankIncomeTotals',
              },
              {
                path: 'balances',
                name: 'consoleBankBalanceTotals',
              },
              {
                path: 'earnings',
                name: 'consoleBankEarningsTotals',
              },
              {
                path: 'revenue',
                name: 'consoleBankRevenueTotals',
              },
            ],
          },
          {
            path: 'ledgers',
            name: 'consoleBankLedgers',
            component: ConsoleBankLedgersPage,
            children: [
              {
                path: 'campaign/:ledgerCampaignId',
                name: 'consoleBankCampaignLedger',
              },
            ],
          },
          {
            path: 'accounts',
            name: 'consoleBankAccounts',
            component: ConsoleBankAccountsPage,
            redirect: { name: 'consoleBankUserAccounts' },
            children: [
              {
                path: 'users/:accountId?',
                name: 'consoleBankUserAccounts',
              },
              {
                path: 'brands/:accountId?',
                name: 'consoleBankBrandAccounts',
              },
            ],
          },
          {
            path: 'revenue',
            name: 'consoleBankInvoices',
            component: ConsoleBankInvoicesPage,
            redirect: { name: 'consoleBankReadyInvoices' },
            children: [
              {
                path: 'ready',
                name: 'consoleBankReadyInvoices',
              },
              {
                path: 'sent',
                name: 'consoleBankSentInvoices',
              },
              {
                path: 'paid',
                name: 'consoleBankPaidInvoices',
              },
            ],
          },
          {
            path: 'expenses',
            name: 'consoleBankExpenses',
            component: ConsoleBankExpensesPage,
            redirect: { name: 'consoleBankPayoutRequests' },
            children: [
              {
                path: 'payouts/requests',
                name: 'consoleBankPayoutRequests',
              },
              {
                path: 'payouts/recent',
                name: 'consoleBankRecentPayouts',
              },
              {
                path: 'invoices/requests',
                name: 'consoleBankIncomingInvoices',
              },
              {
                path: 'invoices/recent',
                name: 'consoleBankPaidIncomingInvoices',
              },
            ],
          },
          {
            path: 'cases/:caseId?',
            name: 'consoleBankCases',
            component: ConsoleBankFinanceCasesPage,
          },
        ],
      },
      {
        path: 'agencies',
        name: 'consoleAgencies',
        component: ConsoleAgenciesPage,
        children: [
          {
            path: ':agencyId',
            name: 'consoleAgency',
          },
        ],
      },
      {
        path: 'csv',
        name: 'consoleCsv',
        component: ConsoleCsvPage,
      },
      {
        path: 'sounds',
        name: 'consoleSounds',
        component: ConsoleTrackedSoundPage,
      },
      {
        path: 'sales',
        name: 'consoleSales',
        component: ConsoleSalesPage,
        children: [
          {
            path: 'proposal/:proposalId',
            name: 'conosleSalesProposal',
            component: ParentPage,
          },
        ],
      },
    ],
  },
]
