//
//
//
//
//
//

import { getDoc } from '@happstv/shared/util/firebase/firestoreUtils'
import { AGENCY_TALENT_LIST_DOC_PATH } from '@happstv/shared/util/firebase/firestorePaths'

import PublicCreatorList from '@/components/agent/creator/mediaKit/PublicCreatorList.vue'

export default {
  name: 'PublicCreatorListPage',
  components: {
    PublicCreatorList,
  },
  computed: {
    creatorListId() { return this.$route.params.creatorListId },
  },
  data() {
    return {
      creatorList: undefined,
    }
  },
  async mounted() {
    const { creatorListId } = this
    const creatorListDoc = await getDoc(AGENCY_TALENT_LIST_DOC_PATH(creatorListId))
    
    if (!creatorListDoc.exists) {
      this.$router.goBack()
      return
    }
    this.creatorList = { id: creatorListDoc.id, ...(creatorListDoc.data() || {}) }
  },
}
