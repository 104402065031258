const BrandCampaignSplashPage = () => import('@/pages/brands/BrandCampaignSplashPage.vue')

export default [
  {
    path: ':brandCampaignId',
    name: 'campaignSplashScreen',
    component: BrandCampaignSplashPage,
    meta: {
      noBackButton: true,
    },
  },
]
