//
//
//
//

import { getDoc } from '@happstv/shared/util/firebase/firestoreUtils'
import { AGENCY_MEDIA_KIT_DOC_PATH } from '@happstv/shared/util/firebase/firestorePaths'

import PublicMediaKit from '@/components/agent/creator/mediaKit/PublicMediaKit.vue'

export default {
  name: 'PublicMediaKitPage',
  components: {
    PublicMediaKit,
  },
  computed: {
    mediaKitId() { return this.$route.params.mediaKitId },
  },
  data() {
    return {
      mediaKit: undefined,
    }
  },
  async mounted() {
    const { mediaKitId } = this
    const mediaKitDoc = await getDoc(AGENCY_MEDIA_KIT_DOC_PATH(mediaKitId))
    
    if (!mediaKitDoc.exists) {
      this.$router.goBack()
      return
    }
    this.mediaKit = { id: mediaKitDoc.id, ...(mediaKitDoc.data() || {}) }
  },
}
