import {
  BRAND_CAMPAIGN_DOC_PATH,
  BRAND_CAMPAIGN_GROUP_DOC_PATH,
  BRAND_DOC_PATH,
  BRAND_CAMPAIGN_STAT_LOGS_COL_PATH,
  BRAND_CAMPAIGN_OFF_PLATFORM_RELATIONSHIPS_COL_PATH,
  BRAND_CAMPAIGN_USER_RELATIONSHIPS_COL_PATH,
  EXTERNAL_PLATFORM_TRACK_HISTORY_COL_PATH,
  EXTERNAL_PLATFORM_TRACK_DOC_PATH,
} from '@happstv/shared/util/firebase/firestorePaths'

import { 
  getCol,
  getDoc,
  watchCol,
} from '@happstv/shared/util/firebase/firestoreUtils'

import {
  CAMPAIGN_SUBMITTABLE_TYPES,
  getExternalPlatformBySubmittableType,
  getRelevantStatsList,
  getSubmissionTypeFromCampaign,
} from '@happstv/shared/util/brands/brandCampaignUtils'

import { ONE_MONTHISH } from '@happstv/shared/util/timeConstants'

import { date, timestamp, statHourStartDate, nestedObjectAssignWithOptions, filterObjectKeys } from '@happstv/shared/util/utils'

import { TIKTOK_NATIVE } from '@happstv/shared/util/multicastingConstants'

// Helper functions
const aggregateLogValues = (logList) => {
  return nestedObjectAssignWithOptions({ sumNumbers: true }, {}, ...logList)
}

const ensureUpAndToTheRight = (logList) => {
  const reversedOriginal = [...logList].reverse()
  const reversedResult = []

  const originalNextNumericalValues = {}
  let nextNumericalValues = {}
  reversedOriginal.forEach((originalStatLog) => {
    const statLog = { ...originalStatLog }
    const newNextNumericalValues = Object.keys(nextNumericalValues).reduce((prevValue, key) => ({ ...prevValue, [key]: 0 }), {})
    Object.keys(statLog).forEach((key) => {
      const originalValue = statLog[key]
      if (typeof originalValue === 'number') {
        if (nextNumericalValues[key] !== undefined && originalNextNumericalValues[key] !== undefined) {
          statLog[key] = originalNextNumericalValues[key] ? Math.round(nextNumericalValues[key] * Math.min(1, originalValue / originalNextNumericalValues[key])) : 0
        }
        newNextNumericalValues[key] = statLog[key]
        originalNextNumericalValues[key] = originalValue
      }
    })
    reversedResult.push(statLog)
    nextNumericalValues = newNextNumericalValues
  })

  return reversedResult.reverse()
}

const getAverage = array => array.reduce((a, b) => a + b) / array.length

const EXTERNAL_METRIC_SMOOTHING_COUNT = 4
const EXTERNAL_METRIC_SMOOTHING_KEY_LIST = ['viewCount', 'likeCount', 'commentCount', 'shareCount', 'createCount']

const getExternalTrackHistoryInner = async (externalPlatform, soundId) => {
  const TARGET_STAT_LOG_HOURS = [3, 9, 15, 21]
  const selectedStatLogHours = TARGET_STAT_LOG_HOURS

  // Get sound history data
  const soundStatLogCol = await getCol(EXTERNAL_PLATFORM_TRACK_HISTORY_COL_PATH(externalPlatform, soundId), q => q
    .where('logTiming.hourOfDay', 'in', selectedStatLogHours)
    .orderBy('logTiming.date', 'asc'))
  const soundStatLogs = (soundStatLogCol.docs || []).map(doc => doc.data() || {})

  // Filters each stat log
  // For any metric, if at least one of the last five & next five logs are greater than the current, filter out the current
  const adjustedStatLogs = soundStatLogs.map((statLog, i) => {
    const lastWindow = soundStatLogs.slice(Math.max(0, i - EXTERNAL_METRIC_SMOOTHING_COUNT), i)
    const nextWindow = soundStatLogs.slice(i + 1, i + 1 + EXTERNAL_METRIC_SMOOTHING_COUNT)

    const result = { ...statLog }

    EXTERNAL_METRIC_SMOOTHING_KEY_LIST.forEach((key) => {
      const lastValues = lastWindow.map(log => log[key]).filter(value => value !== undefined)
      const nextValues = nextWindow.map(log => log[key]).filter(value => value !== undefined)
      const currentValue = statLog[key]

      if (!lastValues.length || !nextValues.length) return

      const countToAverageNextAndLast = Math.min(lastValues.length, nextValues.length)

      result[key] = getAverage([...lastValues.slice(-countToAverageNextAndLast), currentValue, ...nextValues.slice(0, countToAverageNextAndLast)])
    })

    return result
  })

  return adjustedStatLogs
}

const initState = () => ({
  dashboardLoading: true,
  externalTrackLoading: false,
  coverInfo: undefined,
  aggregateCampaignCohortStats: undefined,
  aggregateSoundCohortStats: undefined,
  aggregateCohortProjectionStats: undefined,
  aggregateCampaignStatLogs: undefined,
  topSubmissionsList: undefined,
  externalTrack: undefined,
  externalTrackData: undefined,
  submissionFeedbackUnsubscribe: undefined,
  submissionFeedbackData: undefined,
  soundStatLogs: undefined,
  customGroupTitle: undefined,
})

export default {
  namespaced: true,
  state: {
    ...initState(),
  },
  mutations: {
    REINIT(state) {
      Object.assign(state, initState())
    },
    SET_DASHBOARD_LOADING(state, payload) {
      state.dashboardLoading = payload
    },
    SET_EXTERNAL_TRACK_LOADING(state, payload) {
      state.externalTrackLoading = payload
    },
    SET_COVER_INFO(state, payload) {
      state.coverInfo = payload
    },
    SET_CAMPAIGN_COHORT_STATS(state, payload) {
      state.aggregateCampaignCohortStats = payload
    },
    SET_SOUND_COHORT_STATS(state, payload) {
      state.aggregateSoundCohortStats = payload
    },
    SET_COHORT_PROJECTION_STATS(state, payload) {
      state.aggregateCohortProjectionStats = payload
    },
    SET_CAMPAIGN_STAT_LOGS(state, payload) {
      state.aggregateCampaignStatLogs = payload
    },
    SET_TOP_SUBMISSIONS_LIST(state, payload) {
      state.topSubmissionsList = payload
    },
    SET_EXTERNAL_TRACK(state, payload) {
      state.externalTrack = payload
    },
    SET_EXTERNAL_TRACK_DATA(state, payload) {
      state.externalTrackData = payload
    },
    SET_FEEDBACK_UNSUBSCRIBE(state, payload) {
      state.submissionFeedbackUnsubscribe = payload
    },
    SET_FEEDBACK_DATA(state, payload) {
      state.submissionFeedbackData = payload
    },
    SET_SOUND_STAT_LOGS(state, payload) {
      state.soundStatLogs = payload
    },
    SET_CUSTOM_GROUP_TITLE(state, payload) {
      state.customGroupTitle = payload
    },
  },
  actions: {
    REINIT({ commit }) {
      commit('REINIT')
    },
    async getCampaignDashboardData({ commit }, { brandCampaignId, brandCampaignGroupId }) {
      commit('SET_DASHBOARD_LOADING', true)

      let brandCampaignIds
      if (brandCampaignGroupId) {
        const brandCampaignGroupDoc = await getDoc(BRAND_CAMPAIGN_GROUP_DOC_PATH(brandCampaignGroupId))
        const { brandCampaignIds: groupCampaignIds = [], customTitle } = brandCampaignGroupDoc.data() || {}
        if (!groupCampaignIds.length) return { success: false, error: 'No brand campaigns associated with group.' }
        if (customTitle) commit('SET_CUSTOM_GROUP_TITLE', customTitle)
        brandCampaignIds = groupCampaignIds
      } else if (brandCampaignId) {
        const campaignDoc = await getDoc(BRAND_CAMPAIGN_DOC_PATH(brandCampaignId))
        const { dashboardMergeBrandCampaignIds = [] } = campaignDoc.data() || {}
        brandCampaignIds = [brandCampaignId, ...dashboardMergeBrandCampaignIds]
      }

      const coverInfo = {}
      const allCampaignStats = []
      const allCampaignProjectionStats = []
      const campaignStatLogList = []
      const TARGET_STAT_LOG_HOURS = [3, 9, 15, 21]
      const TARGET_STAT_LOG_HOURS_BACKOFF = [3, 15]
      let selectedStatLogHours

      // Get top ten on and off platform submissions across the campaigns
      // There are two on-platform queries (one for each cohort) because one 'in' clause is allowed per query
      const topTenOnPlatformApprovedSubmissionsPromise = getCol(BRAND_CAMPAIGN_USER_RELATIONSHIPS_COL_PATH, q => q
        .where('dashboardSortScore', '>', 0)
        .where('brandCampaignId', 'in', brandCampaignIds)
        .where('cohort', '==', 'submitted-approved')
        .orderBy('dashboardSortScore', 'desc'))

      const topTenOnPlatformCertifiedSubmissionsPromise = getCol(BRAND_CAMPAIGN_USER_RELATIONSHIPS_COL_PATH, q => q
        .where('dashboardSortScore', '>', 0)
        .where('brandCampaignId', 'in', brandCampaignIds)
        .where('cohort', '==', 'submitted-approved-certified')
        .orderBy('dashboardSortScore', 'desc'))

      const topTenOffPlatformSubmissionsPromise = getCol(BRAND_CAMPAIGN_OFF_PLATFORM_RELATIONSHIPS_COL_PATH, q => q
        .where('dashboardSortScore', '>', 0)
        .where('brandCampaignId', 'in', brandCampaignIds)
        .where('isSubmitted', '==', true)
        .orderBy('dashboardSortScore', 'desc'))

      await Promise.all(brandCampaignIds.map(async (brandCampaignId, index) => {
        const brandCampaignDoc = await getDoc(BRAND_CAMPAIGN_DOC_PATH(brandCampaignId))
        const brandCampaign = { id: brandCampaignDoc.id, ...(brandCampaignDoc.data() || {}) }
        const { creationDate, brandId, chartStartDate, chartEndDate } = brandCampaign
        const olderThanMonth = ((date(chartEndDate)?.getTime() || Date.now()) - (date(chartStartDate)?.getTime() || date(creationDate)?.getTime() || 0)) > ONE_MONTHISH
        selectedStatLogHours = olderThanMonth ? TARGET_STAT_LOG_HOURS_BACKOFF : TARGET_STAT_LOG_HOURS

        if (index === 0) {
          // Use the sound page data from first campaign
          const { soundId } = brandCampaign
          const submissionType = getSubmissionTypeFromCampaign(brandCampaign)
          const externalPlatform = getExternalPlatformBySubmittableType(submissionType)
          // TODO: Update this conditional when we set up IG sound tracking
          if (soundId && submissionType === CAMPAIGN_SUBMITTABLE_TYPES.TIKTOK_POST) {
            (async () => {
              commit('SET_EXTERNAL_TRACK_LOADING', true)
              const soundDoc = await getDoc(EXTERNAL_PLATFORM_TRACK_DOC_PATH(externalPlatform, soundId))
              const soundData = { id: soundDoc.id,  ...(soundDoc.data() || {}) }
              const aggregateSoundCohortStats = filterObjectKeys(soundData, ['createCount', 'viewCount', 'likeCount', 'commentCount', 'shareCount'])
              const externalTrackStatLogs = [
                ...await getExternalTrackHistoryInner(externalPlatform, soundId),
                {
                  logTiming: {
                    date: new Date(),
                  },
                  ...aggregateSoundCohortStats,
                },
              ]
              if (externalTrackStatLogs) commit('SET_EXTERNAL_TRACK_DATA', ensureUpAndToTheRight(externalTrackStatLogs))
              if (aggregateSoundCohortStats) commit('SET_SOUND_COHORT_STATS', aggregateSoundCohortStats)
              commit('SET_EXTERNAL_TRACK', soundData)
              commit('SET_EXTERNAL_TRACK_LOADING', false)
            })()
          }

          // Use the first campaign to fill the campaign-specific info
          const brandDoc = await getDoc(BRAND_DOC_PATH(brandId))
          const brand = { id: brandDoc.id, ...(brandDoc.data() || {}) }
          Object.assign(coverInfo, { brandCampaign, brand })
        }

        const logDateQueryAdditionList = []
        if (chartStartDate) {
          logDateQueryAdditionList.push(q => q.where('logTiming.date', '>=', timestamp(chartStartDate)))
        }
        if (chartEndDate) {
          logDateQueryAdditionList.push(q => q.where('logTiming.date', '<=', timestamp(chartEndDate)))
        }

        // Query stat logs for every campaign
        const campaignStatLogCol = await getCol(BRAND_CAMPAIGN_STAT_LOGS_COL_PATH(brandCampaignId), q => logDateQueryAdditionList.reduce((q1, addition) => addition(q1), q)
          .where('logTiming.hourOfDay', 'in', selectedStatLogHours)
          .orderBy('logTiming.date', 'asc'))
        const campaignStatLogDocs = campaignStatLogCol.docs || []
        const campaignStatLogs = campaignStatLogDocs
          .map(doc => ({ id: doc.id, brandCampaignId, ...doc.data() || {} }))
        campaignStatLogList.push(campaignStatLogs)

        if (!chartEndDate) {
          allCampaignStats.push(...getRelevantStatsList(brandCampaign))
          allCampaignProjectionStats.push(...getRelevantStatsList(brandCampaign))
        } else if (campaignStatLogs.length) {
          allCampaignStats.push(...getRelevantStatsList(campaignStatLogs[campaignStatLogs.length - 1]))
          allCampaignProjectionStats.push(...getRelevantStatsList(campaignStatLogs[campaignStatLogs.length - 1]))
        }
      }))

      // Accumulate cohort stats
      const aggregateCohortCampaignStats = aggregateLogValues(allCampaignStats)
      const aggregateCohortProjectionStats = aggregateLogValues(allCampaignProjectionStats)

      // Accumulate stat logs
      const aggregateCampaignStatLogs = []
      const sortedAllLogs = campaignStatLogList
        .flat()
        .filter(({ logTiming = {} }) => logTiming.hourNumber)
        .sort((a,b) => a.logTiming.hourNumber - b.logTiming.hourNumber)

      if (sortedAllLogs.length) {
        const minHourNumber = sortedAllLogs[0].logTiming.hourNumber
        const maxHourNumber = sortedAllLogs[sortedAllLogs.length - 1].logTiming.hourNumber

        for (let hourNumber = minHourNumber; hourNumber <= maxHourNumber; hourNumber += 1) {
          const hourStartDate = statHourStartDate(hourNumber)
          if (selectedStatLogHours.includes(hourNumber % 24) && hourStartDate < new Date()) {
            const logsToAdd = brandCampaignIds
              .map((brandCampaignId) => {
                return sortedAllLogs
                  .filter(log => log.brandCampaignId === brandCampaignId)
                  .filter(({ logTiming = {} }) => logTiming.hourNumber <= hourNumber)
                  .reverse()[0]
              })
              .filter(log => log)
            const newDataPoint = aggregateLogValues(logsToAdd.map(log => aggregateLogValues(getRelevantStatsList(log))))
            aggregateCampaignStatLogs.push({ ...newDataPoint, logTiming: { date: hourStartDate } })
          }
        }
      }

      aggregateCampaignStatLogs.push({
        logTiming: {
          date: new Date(),
        },
        ...aggregateCohortCampaignStats,
      })

      const topTenOnPlatformApprovedSubmissionDocs = (await topTenOnPlatformApprovedSubmissionsPromise).docs || []
      const topTenOnPlatformCertifiedSubmissionDocs = (await topTenOnPlatformCertifiedSubmissionsPromise).docs || []
      const topTenOffPlatformSubmissionDocs = (await topTenOffPlatformSubmissionsPromise).docs || []

      const topSubmissionsList = [...topTenOnPlatformApprovedSubmissionDocs, ...topTenOnPlatformCertifiedSubmissionDocs, ...topTenOffPlatformSubmissionDocs]
        .map(doc => ({ id: doc.id, ...(doc.data() || {}) }))
        // Just in case hideFromDashboard and dashboardSortScore get out of sync
        // Also filter out any submissions that don't have submissionData propagated
        .filter(({ hideFromDashboard, submissionData }) => !hideFromDashboard && (submissionData !== undefined))
        .sort((a, b) => (b.dashboardSortScore - a.dashboardSortScore) || a.id.localeCompare(b.id))

      commit('SET_DASHBOARD_LOADING', false)
      commit('SET_COVER_INFO', coverInfo)
      commit('SET_CAMPAIGN_COHORT_STATS', aggregateCohortCampaignStats)
      commit('SET_COHORT_PROJECTION_STATS', aggregateCohortProjectionStats)
      commit('SET_CAMPAIGN_STAT_LOGS', ensureUpAndToTheRight(aggregateCampaignStatLogs))
      commit('SET_TOP_SUBMISSIONS_LIST', topSubmissionsList)
    },
    async getBrandFeedback({ commit }, { brandCampaignId }) {
      const unsub = watchCol(`${BRAND_CAMPAIGN_DOC_PATH(brandCampaignId)}/brandSubmissionFeedback`, q => q, (feedbackSnap) => {
        const feedbackDocs = feedbackSnap.docs || []
        const submissionFeedbackData = feedbackDocs.map((doc) => ({ id: doc.id, ...doc.data() || {} }))
        commit('SET_FEEDBACK_DATA', submissionFeedbackData)
      })
      commit('SET_FEEDBACK_UNSUBSCRIBE', unsub)
    },
    // eslint-disable-next-line no-unused-vars
    async checkIfTrackingSound({ commit }, { tiktokSoundId }) {
      const soundDoc = await getDoc(EXTERNAL_PLATFORM_TRACK_DOC_PATH(TIKTOK_NATIVE, tiktokSoundId))
      if (!soundDoc.exists) return false
      const soundData = soundDoc.data() || {}
      if (!soundData.trackingEnabled) return false

      return true
    },
    async getSoundDashboardData({ commit }, { tiktokSoundId }) {
      // Get sound cover data
      const soundDoc = await getDoc(EXTERNAL_PLATFORM_TRACK_DOC_PATH(TIKTOK_NATIVE, tiktokSoundId))
      const soundData = soundDoc.data() || {}
      const cohortStats = filterObjectKeys(soundData, ['createCount', 'viewCount', 'likeCount', 'commentCount', 'shareCount'])
      commit('SET_COVER_INFO', { soundData: filterObjectKeys(soundData, ['displayName', 'coverImageUrl', 'dashboardName', 'authorName', 'latestRefreshStatsHourlyUntilDate']) })
      commit('SET_SOUND_COHORT_STATS', cohortStats)

      const statLogs = [
        ...await getExternalTrackHistoryInner(TIKTOK_NATIVE, tiktokSoundId),
        { // Add the current snapshot as the latest data point
          logTiming: {
            date: new Date(),
          },
          ...cohortStats,
        },
      ]

      commit('SET_SOUND_STAT_LOGS', ensureUpAndToTheRight(statLogs))
    },
  },
}
