import { formatNumber, numberMagnitudeString, flattenObjectKeys } from '@happstv/shared/util/utils'
import { TIKTOK_NATIVE, INSTAGRAM_NATIVE, YOUTUBE_NATIVE, SNAPCHAT_NATIVE } from '@happstv/shared/util/multicastingConstants'
import { CAMPAIGN_SUBMITTABLE_TYPES, getExternalPlatformBySubmittableType } from '@happstv/shared/util/brands/brandCampaignUtils'
import { INSTAGRAM_SUBMITTABLE_PRODUCT_TYPES } from '@happstv/shared/constants/connectedAccounts/instagramConstants'

export const getChartProps = (statList = [], seriesName, earningsKey, highlightColor = '#FFF') => {
  const data = statList.map(({ chartDate, stats }) => ({ x: chartDate, y: stats.managedEarnings?.[earningsKey] || 0 }))

  return {
    series: [
      { name: seriesName, data },
    ],
    options: {
      grid: {
        borderColor: '#FFFFFF1F',
        padding: {
          right: 50,
        },
      },
      chart: {
        animations: {
          enabled: false,
        },
        background: 'transparent',
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          format: 'MMM \'yy',
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: true,
          color: '#FFF',
        },
      },
      yaxis: [
        {
          title: {
            text: 'USD',
          },
          labels: {
            formatter: value => `$${numberMagnitudeString(value)}`,
          },
        },
      ],
      colors: [highlightColor],
      tooltip: {
        enabled: true,
        x: {
          format: 'MMMM yyyy',
        },
        y: [
          {
            formatter: value => `$${formatNumber(value)}`,
          },
        ],
      },
      theme: {
        mode: 'dark',
      },
      stroke: {
        width: [4],
        lineCap: ['round'],
      },
    },
  }
}

export const AGENCY_NAV_ROUTE_NAME_LIST_BY_AGENCY_ROLE = {
  admin: [
    'agencyAdminStats',
    'agencyAdminAgents',
    'agencyCreators',
    'agencyAdminCreatorLists',
    'agencyCampaigns',
    'agencyAdminWallet',
  ],
  agent: [
    'agencyAgentStats',
    'agencyCreators',
    'agencyAgentCreatorLists',
    'agencyCampaigns',
    'agencyAgentWallet',
    'agencyAgentSettings',
  ],
}

export function getRoutePrefixForAgencyRole(role) {
  return `agency${role.charAt(0).toUpperCase()}${role.substring(1)}`
}

export function getCurrentAgencyRedirectForNewRole(router, newRole) {
  const { currentRoute } = router
  const newRoleRoutePrefix = getRoutePrefixForAgencyRole(newRole)

  if (currentRoute) {
    const { agencyId } = currentRoute.params
    if (agencyId) {
      const { agencyRole: oldRole } = currentRoute.params
      if (oldRole) {
        const roleChangeRoute = { name: currentRoute.name, params: { ...currentRoute.params, agencyRole: newRole }, query: currentRoute.query }
        if (router.resolve(roleChangeRoute).href !== '/') {
          return roleChangeRoute
        }
        
        const oldRoleRoutePrefix = getRoutePrefixForAgencyRole(oldRole)

        const [nameChangeNewRoute] = [...currentRoute.matched]
          .reverse()
          .map(({ name }) => {
            if (!name) return undefined
            const possibleNewRoute = { ...roleChangeRoute, name: name.replace(oldRoleRoutePrefix, newRoleRoutePrefix) }
            if (router.resolve(possibleNewRoute).href === '/') return undefined
            return possibleNewRoute
          })
          .filter(route => route)
        if (nameChangeNewRoute) {
          return nameChangeNewRoute
        }
      }

      return { name: `${newRoleRoutePrefix}Base`, params: { agencyRole: newRole } }
    }
  }

  return { name: 'agentHome' }
}

export const DELIVERABLE_TYPE_PLATFORM_LIST = [
  { key: undefined, label: 'Select a platform (optional)' },
  { key: TIKTOK_NATIVE, label: 'TikTok' },
  { key: INSTAGRAM_NATIVE, label: 'Instagram' },
  { key: YOUTUBE_NATIVE, label: 'YouTube' },
  { key: SNAPCHAT_NATIVE, label: 'Snapchat' },
]

export function getCampaignStatusInfo(campaign) {
  switch (campaign.campaignStatus) {
    case undefined: 
      break
    case 'inProgress': 
      return {
        label: 'In Progress',
        color: '#000',
        backgroundColor: '#FFF',
      }
    case 'pendingFinalization': 
      return {
        label: 'Wrapping Up',
        color: '#000',
        backgroundColor: '#f0c200',
      }
    case 'finalized': 
      return {
        label: 'Completed',
        color: '#FFF',
        backgroundColor: '#007e1f',
      }
    default:
      return {
        label: '[unknown status]',
        color: '#FFF',
        backgroundColor: '#494B58',
      }
  }

  if (campaign.finalResultsFinalized) {
    return {
      label: 'Completed',
      color: '#FFF',
      backgroundColor: '#007e1f',
    }
  }

  if (campaign.earningsEnabled) {
    return {
      label: 'In Progress',
      color: '#000',
      backgroundColor: '#FFF',
    }
  }

  if (campaign.earningsEnabledDate) {
    return {
      label: 'Wrapping Up',
      color: '#000',
      backgroundColor: '#f0c200',
    }
  }

  return {
    label: 'In Setup',
    color: '#FFF',
    backgroundColor: '#494B58',
  }
}

export function getCampaignCreateTypeInfo(campaign) {
  const { submissionType, submissionProductType } = campaign
  if (!submissionType) return undefined
  const platform = getExternalPlatformBySubmittableType(submissionType)

  let label
  switch (submissionType) {
    case CAMPAIGN_SUBMITTABLE_TYPES.TIKTOK_POST:
      label = 'TikTok Posts'
      break
    case CAMPAIGN_SUBMITTABLE_TYPES.INSTAGRAM_POST:
      switch (submissionProductType) {
        case INSTAGRAM_SUBMITTABLE_PRODUCT_TYPES.REELS:
          label = 'IG Reels'
          break
        case INSTAGRAM_SUBMITTABLE_PRODUCT_TYPES.IMAGE:
          label = 'IG Images'
          break
        case INSTAGRAM_SUBMITTABLE_PRODUCT_TYPES.CAROUSEL_ALBUM:
          label = 'IG Carousels'
          break
        case INSTAGRAM_SUBMITTABLE_PRODUCT_TYPES.FEED:
          label = 'IG Grid Posts'
          break
      }
      break
    case CAMPAIGN_SUBMITTABLE_TYPES.YOUTUBE_POST:
      label = 'YouTube Posts'
      break
  }

  if (!label) return undefined

  return { label, platform }
}


const validateExistingDemographic = (val) => {
  if (val === undefined || val === null) return false
  if (typeof val === 'object') return Object.keys(val).length
  if (typeof val === 'number') return val > 0
  return false
}

export function getDemographicsAccountListFromMediaKit(mediaKit) {
  const {
    creatorConnectedAccounts = [],
    manuallyAddedAccounts = [],
    demographicsByAccountUeid = {},
  } = mediaKit

  const dedupedAccountList = [...creatorConnectedAccounts, ...manuallyAddedAccounts]
    .filter(({ accountUeid }, i, a) => accountUeid && a.findIndex(another => another.accountUeid === accountUeid) === i)
    .sort((a, b) => a.accountUeid.localeCompare(b.accountUeid))

  return dedupedAccountList
    .map((account) => ({
      account,
      demographics: demographicsByAccountUeid[account.accountUeid],
    }))
    .filter(({ demographics }) => demographics && Object.values(flattenObjectKeys(demographics)).every(validateExistingDemographic))
}

