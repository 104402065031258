
import ExternalAuthPage from '@/pages/connect/ExternalAuthPage.vue'

export default [
  {
    path: ':platformId',
    name: 'externalAuth',
    component: ExternalAuthPage,
    meta: {
      noBackButton: true,
      noTopNav: true,
      authIndependent: true,
    },
    children: [
      {
        path: 'launch',
        name: 'externalAuthLaunch',
      },
      {
        path: 'login',
        name: 'externalAuthLogin',
      },
      {
        path: 'return',
        name: 'externalAuthReturn',
      },
      {
        path: 'external',
        name: 'externalAuthIframe',
      },
    ],
  },
]
